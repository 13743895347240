<template>
    <v-row>
      <v-col cols="2">
        <v-btn tile small dark :outlined="type == 'date'" color="teal accent-4" class="ml-3 mr-2" @click="type = 'interval'">Interval</v-btn>
        <v-btn tile small dark :outlined="type == 'interval'" color="teal accent-4" @click="type = 'date'">Date</v-btn>
      </v-col>
      <v-col cols="4" v-if="type == 'interval'">
        <v-btn tile small dark :outlined="interval != 30" class="mr-1" color="teal accent-4" @click="interval = 30">30</v-btn>
        <v-btn tile small dark :outlined="interval != 60" class="ml-1 mr-1" color="teal accent-4" @click="interval = 60">60</v-btn>
        <v-btn tile small dark :outlined="interval != 90" class="ml-1 mr-1" color="teal accent-4" @click="interval = 90">90</v-btn>
        <v-btn tile small dark :outlined="interval != 0" class="ml-1 mr-1" color="teal accent-4" @click="interval = 0">All</v-btn>
      </v-col>
      <v-col cols="2" v-if="type == 'date'" class="mb-0 pb-0">
        <v-menu v-model="menu1" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="startDate"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              hide-details
              class="my-0 py-0"
            ></v-text-field>
          </template>
          <v-date-picker v-model="startDate" @input="menu1 = false" :max="endDate"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="2" v-if="type == 'date'" class="mb-0 pb-0">
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="endDate"
              label="End Date"
              readonly
              v-bind="attrs"
              v-on="on"
              clearable
              hide-details
              class="my-0 py-0"
            ></v-text-field>
          </template>
          <v-date-picker v-model="endDate" @input="menu2 = false" :min="startDate" :max="new Date().toISOString().substr(0, 10)"></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="1" v-if="type == 'date'">
        <v-btn @click="getResearch()" small icon>
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
</template>

<script>
export default {
    data: () => ({
        // startDate: new Date().toISOString().substr(0, 10),
        // 开始日期默认为上个月的日期
        startDate: new Date(new Date() - 30 * 24 * 3600 * 1000).toISOString().substr(0, 10),
        // 结束日期默认为今天日期
        endDate: new Date().toISOString().substr(0, 10),
        // 开始日期的框框
        menu1: false,
        // 结束日期的框框
        menu2: false,
        // 选择类型
        type: "interval",
        // 间隔
        interval:30,
    }),
    computed: {
    },
    watch:{
        startDate(val){
          this.$emit('startDate',val)
        },
        endDate(val){
          this.$emit('endDate',val)
        },
        type(val){
          this.$emit('type',val)
        },
        interval(val){
          this.$emit('interval',val)
        }
    },
    mounted() {
      this.$emit('type', this.type);
      if(this.type == "interval"){
        this.$emit('interval', 30);
      } else if(this.type == "date"){
        this.$emit('startDate', this.startDate);
        this.$emit('endDate', this.endDate);
      }
    },
    beforeDestroy() {

    },
    methods: {
      getResearch(){
        this.$emit("getResearch")
      }
    }
  }
</script>