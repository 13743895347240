<template>
    <div>
        <v-container fluid class="pt-0 mt-5">
            <v-row>
                <v-col>
                    <!-- 横条 -->
                    <v-alert :dismissible="false" class="teal accent-4 white--text" dark border="left" icon="as fas fa-search-dollar">
                        <v-row no-gutters class="align-center">
                            <v-col cols="10" class="pa-0">
                                <span>Candle Research</span>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" class="px-5 pt-0">
                    <v-tabs v-model="exchange" dense :slider-size="2" background-color="transparent" color="teal accent-4">
                        <v-tab :href="'#'+item" v-for="(item,index) in exchangeItems" :key="index">
                            <span>{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs v-model="category" class="mt-3" dense color="teal accent-4">
                        <v-tab :href="'#'+item" v-for="(item,index) in categoryItems" :key="index">
                            <span>{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                    <v-tabs v-model="symbol" class="my-5" dense show-arrows next-icon="mdi-menu-right" prev-icon="mdi-menu-left" dark background-color="teal accent-4">
                        <v-tab :href="'#'+item" v-for="(item,index) in symbolItem" :key="index">
                            <span>{{ item }}</span>
                        </v-tab>
                    </v-tabs>
                    <v-row>
                        <research-date @startDate="startDateMethod" @endDate="endDateMethod" @type="typeMethod" @interval="intervalMethod" @getResearch="getResearch()"></research-date>
                        <v-btn x-small dark fab color="teal accent-4" class="mr-5 mt-3" v-if="page == 'right'" @click="page = 'left'">
                            <v-icon x-small>fas fa-arrow-left</v-icon>
                        </v-btn>
                        <v-btn x-small dark fab color="teal accent-4" class="mr-5 mt-3" v-if="page == 'left'" @click="page = 'right'">
                            <v-icon x-small>fas fa-arrow-right</v-icon>
                        </v-btn>
                    </v-row>
                    <div class="mt-5"></div>
                    <div class="text-center">Avg</div>
                    <v-divider></v-divider>
                    <v-data-table :headers="ratioHeader" hide-default-footer disable-sort dense>
                        <template v-slot:body>
                            <tbody>
                                <tr v-if="ratio.ratio0 != null">
                                    <td v-for="i in 24" :key="i">{{ (ratio["ratio" + (i-1)] * 100).toFixed(2) + "%" }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <div class="mt-5"></div>
                    <div class="text-center">Data</div>
                    <v-divider></v-divider>
                    <v-data-table :headers="page == 'left' ? dataLeftHeader : dataRightHeader" hide-default-footer disable-sort dense width="100%">
                        <template v-slot:body>
                            <tbody v-if="page == 'left'">
                                <tr v-for="(item,index) in researchData" :key="index">
                                    <td>{{ item.date}}</td>
                                    <td>{{ item.hlDay.toFixed(decimal)}}</td>
                                    <td v-for="(i,index) in 12" :key="index">{{ item["hlDiff"+(i-1)] == null ? "--" : item["hlDiff"+ (i-1)].toFixed(decimal) }}{{ item["hlDiffDayRatio"+(i-1)] == null ? "" : " (" + (item["hlDiffDayRatio"+ (i-1)] * 100).toFixed(2) +"%)"}}</td>
                                </tr>
                            </tbody>
                            <tbody v-if="page == 'right'">
                                <tr v-for="(item,index) in researchData" :key="index">
                                    <td>{{ item.date}}</td>
                                    <td>{{ item.hlDay.toFixed(decimal)}}</td>
                                    <td v-for="(i,index) in 12" :key="index">{{ item["hlDiff"+(i + 11)] == null ? "--" : item["hlDiff"+ (i + 11)].toFixed(decimal) }}{{ item["hlDiffDayRatio"+(i + 11)] == null ? "" : " (" + (item["hlDiffDayRatio"+ (i + 11)] * 100).toFixed(2) +"%)"}}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-data-table>
                    <v-divider></v-divider>
                    <v-row dense v-if="loading"> 
                        <v-col cols="12">
                            <v-skeleton-loader dense type="table-row-divider@20" class="mx-auto"></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<style>
    table {
        table-layout:fixed;
    }
    .v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
        padding: 0 3px!important;
        -webkit-transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
        transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
    }
</style>
<script>
    import Vue from 'vue';
    import config from '@/config';
    import { mapGetters } from "vuex";
    import ResearchDate from "../../components/ResearchDate"
    export default {
        data() {
            return {
                // 交易所
                exchange: "OKEx" ,
                // 交易所数组
                exchangeItems: ["OKEx","Kraken"],
                // 交易类型
                category: null,
                // 类型数组
                categoryItems: ["spot","futures","swap-usdt"],
                // 选择的交易对
                symbol:null,
                // 支持的交易对对象
                symbolObject: {},
                // 支持的交易对数组
                symbolItem: [],
                // 平均值比例对象
                ratio: {},
                // 数据数组
                researchData: [],
                // 加载
                loading: false,
                // 小数位
                decimal: 2,
                // 显示的数据 默认左边
                page:"left",
                // 开始日期
                startDate: null,
                // 结束日期
                endDate:null,
                // 类型:
                type:null,
                // 周期,间隔
                interval:null,
                // 数据的标题
                // dataHeader: [
                //     {text:"Date",width:"6%"},
                //     {text:"0-1",width:"4.1%"},
                //     {text:"1-2",width:"4.1%"},
                //     {text:"2-3",width:"4.1%"},
                //     {text:"3-4",width:"4.1%"},
                //     {text:"4-5",width:"4.1%"},
                //     {text:"5-6",width:"4.1%"},
                //     {text:"6-7",width:"4.1%"},
                //     {text:"7-8",width:"4.1%"},
                //     {text:"8-9",width:"4.1%"},
                //     {text:"9-10",width:"4.1%"},
                //     {text:"10-11",width:"4.1%"},
                //     {text:"11-12",width:"4.1%"},
                //     {text:"12-13",width:"4.1%"},
                //     {text:"13-14",width:"4.1%"},
                //     {text:"14-15",width:"4.1%"},
                //     {text:"15-16",width:"4.1%"},
                //     {text:"16-17",width:"4.1%"},
                //     {text:"17-18",width:"4.1%"},
                //     {text:"18-19",width:"4.1%"},
                //     {text:"19-20",width:"4.1%"},
                //     {text:"20-21",width:"4.1%"},
                //     {text:"21-22",width:"4.1%"},
                //     {text:"22-23",width:"4.1%"},
                //     {text:"23-0",width:"4.1%"},
                // ],
                // 数据左边的标题
                dataLeftHeader: [
                    {text:"Date"},
                    {text:"Day"},
                    {text:"0-1"},
                    {text:"1-2"},
                    {text:"2-3"},
                    {text:"3-4"},
                    {text:"4-5"},
                    {text:"5-6"},
                    {text:"6-7"},
                    {text:"7-8"},
                    {text:"8-9"},
                    {text:"9-10"},
                    {text:"10-11"},
                    {text:"11-12"},
                ],
                // 数据左边的标题
                dataRightHeader: [
                    {text:"Date"},
                    {text:"Day"},
                    {text:"12-13"},
                    {text:"13-14"},
                    {text:"14-15"},
                    {text:"15-16"},
                    {text:"16-17"},
                    {text:"17-18"},
                    {text:"18-19"},
                    {text:"19-20"},
                    {text:"20-21"},
                    {text:"21-22"},
                    {text:"22-23"},
                    {text:"23-0"},
                ],
                // 平均值的标题
                ratioHeader: [
                    {text:"0-1"},
                    {text:"1-2"},
                    {text:"2-3"},
                    {text:"3-4"},
                    {text:"4-5"},
                    {text:"5-6"},
                    {text:"6-7"},
                    {text:"7-8"},
                    {text:"8-9"},
                    {text:"9-10"},
                    {text:"10-11"},
                    {text:"11-12"},
                    {text:"12-13"},
                    {text:"13-14"},
                    {text:"14-15"},
                    {text:"15-16"},
                    {text:"16-17"},
                    {text:"17-18"},
                    {text:"18-19"},
                    {text:"19-20"},
                    {text:"20-21"},
                    {text:"21-22"},
                    {text:"22-23"},
                    {text:"23-0"},
                ],
            }
        },
        components:{
            ResearchDate
        },
        computed: {
            ...mapGetters(['user','spotSymbolInfos','swapUsdtSymbolInfos','futuresDecimalNumbers','exchangeSupportedSpotSymbols','exchangeSupportedFuturesSymbols','exchangeSupportedSwapUsdtSymbols']),
        },
        watch: {
            category(val){
                this.getSymbols();
                if (this.symbol) {
                    if(val == "spot"){
                        this.getSpotDecimalPlace(this.exchange,this.symbol);
                    }
                    if(val == "futures"){
                        this.decimal = this.futuresDecimalNumbers[this.symbol];
                    }
                    if(val == "swap-usdt"){
                        this.getSwapDecimalPlace(this.exchange,this.symbol);
                    }
                }
            },
            symbol(val){
                this.getResearch();
                if (val) {
                    if(this.category == "spot"){
                        this.getSpotDecimalPlace(this.exchange,val);
                    }
                    if(this.category == "futures"){
                        this.decimal = this.futuresDecimalNumbers[val];
                    }
                    if(this.category == "swap-usdt"){
                        this.getSwapDecimalPlace(this.exchange,val);
                    }
                }
            },
            exchange(val){
                this.getSymbols();
                this.getResearch();
            },
            type(val){
                this.getResearch();
            },
            interval(val){
                this.getResearch();
            },
        },
        mounted() {
            if (!this.user.token) {
                this.$router.push({ name: 'login' })
            };
            this.getSymbols();
        },
        created() {
           
        },
        methods: {
            // 获取支持的交易对
            getSymbols(){
            //    let api = config.baseUrl + (this.category == "spot" ? "/symbol/public/getExchangeSupportedSpotSymbols" : "/symbol/public/getExchangeSupportedFuturesSymbols");
            //    this.$http.get(api).then(res=>{
            //        let data = res.data;
            //        if(data.code == 200){
            //            this.symbolObject = data.result;
            //         }else{
            //             this.$store.dispatch('snackbarMessageHandler', "error");
            //         }
            //    },err=>{
            //        this.$store.dispatch('snackbarMessageHandler', "error");
            //    })
                this.symbolItem = [];
                this.symbol = null;
                if(this.category == "spot"){
                    this.symbolObject = this.exchangeSupportedSpotSymbols;
                }
                if(this.category == "futures"){
                    this.symbolObject = this.exchangeSupportedFuturesSymbols;
                }
                if(this.category == "swap-usdt"){
                    this.symbolObject = this.exchangeSupportedSwapUsdtSymbols;
                }
                let symbols = this.symbolObject[this.exchange.toLowerCase()];
                if(symbols != null){
                    for(var i = 0 ; i < symbols.length ; i++){
                        this.symbolItem.push(symbols[i].symbol);
                    }
                }
            },
            getResearch(){
               if(this.exchange != null && this.symbol != null && this.category != null){
                    this.loading = true;
                    this.ratio = {};
                    this.researchData = [];
                    this.page = "left";
                    let api = config.baseUrl + "/admin/private/research?exchange=" + this.exchange + "&symbol=" + this.symbol + "&tradingCategory=" + this.category + "&type=" +this.type;
                    if(this.type == 'interval'){
                        if(this.interval != null){
                            api = api + "&interval=" + this.interval;
                        }
                    }
                    if(this.type == 'date'){
                        if(this.startDate != null){
                            api = api + "&startDate=" + this.startDate;
                        }
                        if(this.endDate != null){
                         api = api + "&endDate=" +this.endDate;
                        }
                    }
                    this.$http.get(api).then(res=>{
                        let data = res.data;
                        if(data.code == 200){
                            this.ratio = data.result.ratio;
                            this.researchData = data.result.data;
                            this.loading = false;
                            }else{
                                this.$store.dispatch('snackbarMessageHandler', data.message);
                                this.loading = false;
                            }
                    },err=>{
                        this.$store.dispatch('snackbarMessageHandler', "error");
                        this.loading = false;
                    })
               }
            },
            // 获取小数位
            getSpotDecimalPlace(exchange, symbol) {
                if (exchange == null || symbol == null) {
                    this.decimal = 2;
                    return;
                }
                let currentExchangeSpotSymbolInfos = this.spotSymbolInfos[exchange.toLowerCase()];
                for (let i = 0, len = currentExchangeSpotSymbolInfos.length; i < len; i++) {
                    if (currentExchangeSpotSymbolInfos[i].symbol == symbol) {
                        this.decimal = currentExchangeSpotSymbolInfos[i].priceDecimalPlace;
                    }
                }
            },
            // 获取小数位
            getSwapDecimalPlace(exchange, symbol){
                if (exchange == null || symbol == null) {
                    this.decimal = 8;
                    return;
                }
                let currentExchangeSwapUsdtSymbolInfos = this.swapUsdtSymbolInfos[exchange.toLowerCase()];
                for(let i = 0, len = currentExchangeSwapUsdtSymbolInfos.length; i < len; i++){
                    if (currentExchangeSwapUsdtSymbolInfos[i].symbol == symbol) {
                        this.decimal = currentExchangeSwapUsdtSymbolInfos[i].priceDecimalPlace;
                    }
                }
            },
            startDateMethod(val){
                this.startDate = val;
            },
            endDateMethod(val){
                this.endDate = val;
            },
            typeMethod(val){
                this.type = val;
            },
            intervalMethod(val){
                this.interval = val;
            },
        }
    }
</script>

<style>

</style>